import React, {Component} from "react";
import TinySlider from "tiny-slider-react";
import Slider from "../components/Slider";

const settingSlider = {
    mode: "gallery",
    lazyload: true,
    nav: false,
    mouseDrag: true,

    //auto
    autoplay: true,
    controls: false,

    //botón stop
    autoplayButtonOutput: false,

    loop: true,
    items: 1,
    speed: 2000,
    autoplayTimeout: 4000,
};

class IndexSlider extends Component {
    render() {
        return <TinySlider settings={settingSlider}>
            <Slider
                Title={
                    <>
                        Soluciones
                        <br/>
                        Inteligentes
                    </>
                }
                Description="Pensado en las necesidades reales de tu negocio"
                Image="assets/images/slider/soluciones-inteligentes.png"
            />

            <Slider
                Title={
                    <>
                        Desarrollo
                        <br/>
                        de Aplicativos
                    </>
                }
                Description="A la medida de tus necesidades"
                Image="assets/images/slider/desarrollo-apps.png"
            />

            <Slider
                Title={
                    <>
                        Desarrollo
                        <br/>
                        Seguro
                    </>
                }
                Description="Contamos con la certificación ISO 27001"
                Image="assets/images/slider/iso-27001.png"
            />
        </TinySlider>;
    }
}
export default IndexSlider;
